import React from 'react';
import {Link, PageProps} from 'gatsby';
import Layout from '@/components/Layout';
import Bowser from 'bowser';
import {OutboundLink} from 'gatsby-plugin-google-analytics';

// TODO: replace card flippers with a custom React component that works across all browsers
let isFirefox = false;
if (typeof window !== 'undefined') {
   const browser = Bowser.getParser(window.navigator.userAgent);
   isFirefox = browser.getBrowserName() === 'Firefox';
}

const renderCardFlipper = () => (
   <div className="row center-xs card-language-wrap">
      <div className="col-xs-6 col-sm-3 col-md-1">
         <div className="stage">
            <div className="flashcard one top-leftStart-burst"><span className="ui-border-element">
               <div className="card-language front"><img src="/img/words/french-dark.svg" alt="câlin" />
               </div>
               <div className="card-language back"><img src="/img/words/french-yellow.svg" alt="Hug" /></div>
            </span></div>
         </div>
      </div>
      <div className="col-xs-6 col-sm-3 col-md-1">
         <div className="stage">
            <div className="flashcard two top-leftStart-burst"><span className="ui-border-element">
               <div className="card-language front"><img src="/img/words/spanish-dark.svg" alt="adiós" />
               </div>
               <div className="card-language back"><img src="/img/words/spanish-yellow.svg" alt="Goodbye" />
               </div>
            </span></div>
         </div>
      </div>
      <div className="col-xs-6 col-sm-3 col-md-1">
         <div className="stage">
            <div className="flashcard three top-leftStart-burst"><span className="ui-border-element">
               <div className="card-language front"><img src="/img/words/japanese-dark.svg"
                  alt="word-japanese-dark" /></div>
               <div className="card-language back"><img src="/img/words/japanese-yellow.svg"
                  alt="I'm fine" /></div>
            </span></div>
         </div>
      </div>
      <div className="col-xs-6 col-sm-3 col-md-1">
         <div className="stage">
            <div className="flashcard four top-leftStart-burst"><span className="ui-border-element">
               <div className="card-language front"><img src="/img/words/german-dark.svg" alt="märz" /></div>
               <div className="card-language back"><img src="/img/words/german-yellow.svg" alt="March" />
               </div>
            </span></div>
         </div>
      </div>
   </div>
);

const Home: React.FC<PageProps> = () => (
   <Layout pageTitle="Lingco Language Labs">
      <section className="hero">
         <div className="container-fluid">
            <div className="hero-content-wrap">
               <div className="row center-xs">
                  <div className="col-xs-12 col-md-8 col-lg-7">
                     <h1>Enhance your Language Classroom</h1>
                  </div>
               </div>
               <div className="row center-xs">
                  <div className="col-xs-12 col-md-6 col-lg-7">
                     <p className="text-larger">Lingco Classroom provides the tools for instructors to elevate the
                            language learning experience. <br />Our platform uses adaptive technology to help every
                            student reach fluency.</p><OutboundLink
                        href="https://calendly.com/d/ymc-c2t-4x8"
                        target="_blank" rel="noreferrer"><button>Schedule a Demo</button></OutboundLink>
                  </div>
               </div>
               <div className="row center-xs">
                  <div className="col-xs-12 hero-screen"><img src="/img/screen-laptop.svg" /></div>
               </div>
            </div>
         </div>
      </section>
      <section className="platform-features">
         <div className="container-fluid">
            <div className="row">
               <div className="col-xs-12 col-sm-9 col-lg-3 col-md-offset-2">
                  <h1>Lingco Classroom</h1>
                  <p className="text-larger">Built from the ground up with the student experience in mind, we make
                        learning fun, efficient and convenient.</p>
               </div>
            </div>
            <div className="row">
               <div className="col-xs-12 col-sm-1">
                  <div className="text-verticle one"><img src="/img/text-verticle-one.svg" /></div>
               </div>
               <div className="col-xs-12 col-sm-10">
                  <div className="feature-illustration"><img src="/img/main-illustration.png" /></div>
               </div>
            </div>
            <div className="row center-xs">
               <div className="col-xs-12 col-sm-6 col-lg-4">
                  <div className="row">
                     <div className="col-xs-12 col-sm-3">
                        <div className="feature-icon"><img src="/img/icons/camera-portrait-mode.svg" /></div>
                     </div>
                     <div className="col-xs-12 col-sm-9">
                        <h4>Individualized Instruction</h4>
                        <p>Built from the ground up for differentiated instruction, Lingco can identify a student's
                        level and dynamically deliver the content that they need to progress. From an
                                introductory student to a near native speaker, Lingco is for everyone.</p>
                     </div>
                  </div>
               </div>
               <div className="col-xs-12 col-sm-6 col-lg-4">
                  <div className="row">
                     <div className="col-xs-12 col-sm-3">
                        <div className="feature-icon"><img src="/img/icons/content-layers-show.svg" /></div>
                     </div>
                     <div className="col-xs-12 col-sm-9">
                        <h4>Modern Tools</h4>
                        <p>Lingco provides the tools that teachers need to help students reach fluency. From
                        completely customizable content to an intuitive classroom interface, we give you the
                                power to do things you couldn’t do before.</p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section className="classroom-features">
         <div className="container-fluid">
            <div className="row center-xs">
               <div className="col-xs-12 col-sm-9 col-md-6">
                  <div className="title-content text-center">
                     <h1>Platform Features</h1>
                     <p className="text-larger">We built a modern platform to give instructors the tools needed to
                            successfully run a language course in today’s world.</p>
                  </div>
               </div>
            </div>
            <div className="row middle-xs">
               <div className="col-xs-12 col-md-1">
                  <div className="text-verticle"><img src="/img/text-verticle-two.svg" /></div>
               </div>
               <div className="col-xs-12 col-md-5">
                  <div className="feature-ipad"><img src="/img/screen-ipad.svg" /></div>
               </div>
               <div className="col-xs-12 col-md-4 col-md-offset-1 column-content">
                  <div className="row">
                     <div className="col-xs-12 col-sm-3">
                        <div className="feature-icon"><img src="/img/icons/organization-flowchart-3.svg" /></div>
                     </div>
                     <div className="col-xs-12 col-sm-9">
                        <h4><Link to="/platform/personalized-learning">Personalized Learning</Link></h4>
                        <p>With adaptive questioning, Lingco can quickly and accurately determine what a student
                        knows and change the content presented to meet the student where they're at, regardless
                                of their level.</p>
                     </div>
                  </div>
                  <div className="row">
                     <div className="col-xs-12 col-sm-3">
                        <div className="feature-icon"><img src="/img/icons/paper-grade-a.svg" /></div>
                     </div>
                     <div className="col-xs-12 col-sm-9">
                        <h4><Link to="/platform/course-management">Course Management</Link></h4>
                        <p>Integrated gradebooks with easy-to-use exporting, reusable content and LMS integrations
                                make it painless to incorporate Lingco alongside existing tools.</p>
                     </div>
                  </div>
                  <div className="row">
                     <div className="col-xs-12 col-sm-3">
                        <div className="feature-icon"><img src="/img/icons/planet-book.svg" /></div>
                     </div>
                     <div className="col-xs-12 col-sm-9">
                        <h4><Link to="/platform/engaging-content">Engaging Content</Link></h4>
                        <p>Our content creation tools give you the ability create the course that you envision.
                        Immerse students in the target language with authentic content, enriched by our engaging
                                question types.</p>
                     </div>
                  </div>
                  <div className="row">
                     <div className="col-xs-12 col-sm-3">
                        <div className="feature-icon"><img src="/img/icons/file-statistic-new-2.svg" /></div>
                     </div>
                     <div className="col-xs-12 col-sm-9">
                        <h4><Link to="/platform/insightful-analytics">Insightful Analytics</Link></h4>
                        <p>Understand students' progress with our real-time analytics and identify areas that
                                students need help. With Lingco, there are fewer surprises on test day.</p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div className="section-divider"></div>
      </section>
      <section className="how-it-works">
         <div className="container-fluid">
            <div className="row center-xs">
               <div className="col-xs-12 col-sm-9 col-md-6">
                  <div className="title-content text-center">
                     <h1>How It Works</h1>
                     <p className="text-larger">Powered by artificial intelligence, Lingco frees instructors to spend
                            less time grading homework and more time working with students.</p>
                  </div>
               </div>
            </div>
            {!isFirefox ? renderCardFlipper() : <div style={{ paddingBottom: 70 }}></div>}
            <div className="row center-xs">
               <div className="col-xs-12 col-sm-6 col-lg-4">
                  <div className="row">
                     <div className="col-xs-12 col-sm-3">
                        <div className="feature-icon"><img src="/img/icons/highlight.svg" /></div>
                     </div>
                     <div className="col-xs-12 col-sm-9">
                        <h4>AI-Assisted Grading</h4>
                        <p>Instructors can efficiently grade and give feedback on groups of responses at a time.
                        With Lingco, instructors can turn assignments around in a fraction of the time with
                                better feedback.</p>
                     </div>
                  </div>
               </div>
               <div className="col-xs-12 col-sm-6 col-lg-4">
                  <div className="row">
                     <div className="col-xs-12 col-sm-3">
                        <div className="feature-icon"><img src="/img/icons/devices.svg" /></div>
                     </div>
                     <div className="col-xs-12 col-sm-9">
                        <h4>Always Accessible</h4>
                        <p>Take learning on the go and make progress with our bite-sized study sessions. Whether on
                                the bus or between classes, Lingco is there with our Web, iPhone, and Android apps.</p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div className="section-divider"></div>
      </section>
      <section className="team">
         <div className="container-fluid">
            <div className="row center-xs">
               <div className="col-xs-12 col-sm-9 col-md-6">
                  <div className="title-content text-center">
                     <h1>Our Team</h1>
                     <p className="text-larger">We're built for students by students and for instructors by instructors.
                            Our wide range of perspectives help ensure that Lingco's the platform for everyone.</p>
                  </div>
               </div>
            </div>
            <div className="row center-xs team-member-wrap">
               <div className="col-xs-6 col-sm-3 col-md-2">
                  <div className="team-member"><img src="/img/team_photos/Seth.png" /></div>
                  <h5>Seth Killian</h5>
                  <p>Product</p>
               </div>
               <div className="col-xs-6 col-sm-3 col-md-2">
                  <div className="team-member"><img src="/img/team_photos/Angie.png" /></div>
                  <h5>Angie Pope</h5>
                  <p>Operations</p>
               </div>
               <div className="col-xs-6 col-sm-3 col-md-2">
                  <div className="team-member"><img src="/img/team_photos/Reuben.png" /></div>
                  <h5>Reuben Levinsohn</h5>
                  <p>Finance</p>
               </div>
            </div>
         </div>
      </section>
   </Layout>
);

export default Home;
